import { get, post, put } from './index.js';

/**
 * 获取进项详情
 * @param params Id
 * @returns {Promise<any>} 进项详情
 * @constructor
 */
export const ReqCertificationGetDetail = (params) => {
  return get('/deduct/income/setting/taxno/' + params).then((res) => res);
};

/**
 * 分页查询进项信息
 * @param params
 * @constructor
 */
export const CertificationGetListByPage = (params) => {
  return post('/deduct/income/setting/list', params).then((res) => res);
};

export const deductIncomeSetting = (data) => {
  return put('/deduct/income/setting', data).then(res => res);
}

export const settingTaxno = (data) => {
  return get(`/deduct/income/setting/taxno/${data}`).then(res => res);
}

export const deductIncomeSettingAdd = (data) => {
  return post('/deduct/income/setting', data).then((res) => res);
};
